import React, { useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import { connect } from 'react-redux'
import Layout from "../components/layout"
import { getProducts } from '../state/actions'
import { Landing, Family, News, AboutUs, Footer } from '../components';
import SEO from "../components/seo"

const IndexPage = (props) => {

  let parallax = null;

  const description = "Welcome to HolsumPR! At Holsum, we are always at your service. Providing the highest quality baked products since 1958."

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await props.dispatch(getProducts());
      // ...
    }
    fetchData();
  }, [])


  return (
    <StaticQuery
      query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Holsum-60-anos.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
      render={data =>
        <Layout image={data.placeholderImage.childImageSharp.fluid}>
          <SEO description={description} title="Landing" keywords={[`bread`, `application`, `react`, `bakery`, `puerto rico`]} />
          <Landing />
          <Family />
          <AboutUs />
          <News />
          <Footer />
        </Layout>

      }
    />
  )
}

export default connect()(IndexPage)
